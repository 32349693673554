import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR, { useSWRConfig } from "swr";
import { format, formatDistance } from "date-fns";
import {
  dateObjFormatToAnnArborDateTime,
  genericSWRFetcher,
  hydrateDateObj,
} from "../../utility";
import {
  Alert,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import ApiDataContext from "../../ApiDataContext";
import CommentFeed from "../StandardComponents/CommentFeed";
import TicketStatusPill from "../PagePartials/Tickets/TicketStatusPill";
import TicketContextLink from "../PagePartials/Tickets/TicketContextLink";
import TicketEditMenu from "./PageTicket/TicketEditMenu";
import TicketDescriptionFormDialog from "./PageTicket/TicketDescriptionFormDialog";
import useDialogState from "../../HOC/useDialogState";
import StdConfirmSubmitDialog from "../PagePartials/StdConfirmSubmitDialog";
import TicketReassignDialog from "./PageTicket/TicketReassignDialog";
import TicketCCCard from "./PageTicket/TicketCCCard";
import TicketAddCCDialog from "./PageTicket/TicketAddCCDialog";
import TicketFollowUpFormDialog from "./PageTicket/TicketFollowUpFormDialog";
import TicketTrackThisIconMenu from "./PageTicket/TicketTrackThisIconMenu";
import TicketChangelogTable from "./PageTicket/TicketChangelogTable";
import Grid2 from "@mui/material/Unstable_Grid2";
import TicketContextTable from "../PagePartials/Tickets/TicketContextTable";

const DataBox = ({ title, children }) => {
  return (
    <Paper
      variant="outlined"
      sx={{ flexGrow: 1, flexBasis: 0, p: 1, textAlign: "center" }}
    >
      <Typography variant="body1" sx={{ fontWeight: "normal", mb: 1 }}>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};

const DateBox = ({ followUpOn, finishedOn }) => {
  const dateToUse = finishedOn || followUpOn;
  let label = "Follow Up:";
  if (followUpOn < new Date()) {
    label = "Past Due:";
  }
  if (finishedOn) {
    label = "Finished:";
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography fontSize="large">
        {label}{" "}
        <Box component="span" sx={{ fontWeight: "bold" }}>
          {formatDistance(new Date(), dateToUse)}
          {dateToUse < new Date() ? " ago" : " from now"}
        </Box>
        {" ("}
        <Box component="span">{format(dateToUse, "EEE MMM d, h:mm aa")}</Box>)
      </Typography>
    </Paper>
  );
};

const TitleTicket = ({ id, title }) => {
  const taskIdStr = `TSK-${id.toString().padStart(4, "0")}`;

  return (
    <Tooltip title={title} arrow>
      <Typography
        variant="h5"
        sx={{
          maxWidth: "630px",
          width: "630px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {taskIdStr} - {title}
      </Typography>
    </Tooltip>
  );
};

const PageTicket = ({ api }) => {
  const { me } = useContext(ApiDataContext);
  const { taskId } = useParams();
  const fetcher = genericSWRFetcher(api.getTicket, (key) => [
    key.split("-")[1],
  ]);
  const {
    data: rawData,
    error,
    isLoading,
    mutate,
  } = useSWR(`ticket-${taskId}`, fetcher);
  const { mutate: globalMutate } = useSWRConfig();

  useEffect(() => {
    if (rawData) {
      globalMutate("notificationPreviews");
    }
  }, [globalMutate, rawData]);

  const {
    open: openDesc,
    openFn: handleDescOpen,
    closeFn: handleDescClose,
  } = useDialogState();
  const {
    open: openFinalize,
    openFn: handleFinalizeOpen,
    closeFn: handleFinalizeClose,
  } = useDialogState(false);
  const {
    open: openReassign,
    openFn: handleReassignOpen,
    closeFn: handleReassignClose,
  } = useDialogState(false);
  const {
    open: openCC,
    openFn: handleCCOpen,
    closeFn: handleCCClose,
  } = useDialogState();
  const {
    open: openFollowUp,
    openFn: handleFollowUpOpen,
    closeFn: handleFollowUpClose,
  } = useDialogState();

  const [tabVal, setTabVal] = useState("comments");

  if (error) return <Alert severity="error">{error.message}</Alert>;

  if (isLoading || !rawData) return <CircularProgress />;

  const data = {
    ...rawData,
    follow_up_on: hydrateDateObj(rawData.follow_up_on),
    snooze_until: hydrateDateObj(rawData.snooze_until),
    created_on: hydrateDateObj(rawData.created_on),
    last_activity_on: hydrateDateObj(rawData.last_activity_on),
    finished_on: hydrateDateObj(rawData.finished_on),
    changelog: rawData.changelog.map((cl) => ({
      ...cl,
      change: hydrateDateObj(cl.change),
    })),
    siblings: rawData.siblings.map((s) => ({
      ...s,
      follow_up_on: hydrateDateObj(s.follow_up_on),
      created_on: hydrateDateObj(s.created_on),
      last_activity_on: hydrateDateObj(s.last_activity_on),
      finished_on: hydrateDateObj(s.finished_on),
      last_viewed: hydrateDateObj(s.last_viewed),
    })),
  };

  return (
    <>
      <Grid2 container rowSpacing={2} columnSpacing={2}>
        <Grid2 xs={12}>
          <Stack direction="column" rowGap={2}>
            <Paper sx={{ display: "flex", p: 2 }}>
              <TitleTicket id={data.id} title={data.title} />
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ maxWidth: "250px", textAlign: "right" }}>
                <TicketTrackThisIconMenu
                  taskId={data.id}
                  api={api}
                  ccList={data.cc}
                  onTrackChangeFn={() => mutate()}
                  requesterId={data.requester_id}
                  assigneeId={data.assignee_id}
                />
                <TicketEditMenu
                  actorId={me.id}
                  assigneeId={data.assignee_id}
                  requesterId={data.requester_id}
                  curStatus={data.status}
                  onStatChangeFn={async (status) => {
                    if (status === "closed" || status === "cancelled") {
                      handleFinalizeOpen(status);
                      return null;
                    }

                    try {
                      await api.updateTicketStatus(data.id, status);
                      await mutate();
                    } catch (err) {
                      console.error(err);
                      // show error snackbar?
                      // this is a different way to handle updates...
                    }
                  }}
                  onStateChangeFnMap={{
                    description: handleDescOpen,
                    follow_up: handleFollowUpOpen,
                    requester: () => handleReassignOpen("owner"),
                    assignee: () => handleReassignOpen("assignee"),
                    tracking: handleCCOpen,
                  }}
                />
              </Box>
            </Paper>
          </Stack>
        </Grid2>
        <Grid2 xs={9}>
          <Stack direction="column" rowGap={1}>
            <DateBox
              followUpOn={data.follow_up_on}
              finishedOn={data.finished_on}
            />
            {data.description && (
              <Paper sx={{ p: 2 }}>
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    fontFamily: "monospace",
                  }}
                  variant="body1"
                >
                  {data.description}
                </Typography>
              </Paper>
            )}
            <TicketCCCard
              title="Tracking All Changes"
              ccList={data.cc.filter((c) => c.cc_mode === "notify_on_all")}
            />
            <TicketCCCard
              title="Notify Only On Close"
              ccList={data.cc.filter((c) => c.cc_mode === "notify_on_close")}
            />
          </Stack>
        </Grid2>
        <Grid2 xs={3}>
          <Stack direction="column" rowGap={1}>
            {!!data.context_ident && (
              <DataBox title="Attached To">
                <Typography
                  sx={{
                    display: "block",
                    maxWidth: "190px",
                    width: "190px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <TicketContextLink
                    context={{
                      label: data.context_label,
                      type: data.context_type,
                      ident: data.context_ident,
                    }}
                    def="(none)"
                  />
                </Typography>
              </DataBox>
            )}
            <DataBox title="Status">
              <TicketStatusPill ticket={data} size="medium" caps />
            </DataBox>
            <DataBox title="Requester">
              <Chip
                variant="outlined"
                avatar={
                  <Avatar
                    alt={data.requester_name}
                    src={data.requester_picture}
                  />
                }
                label={data.requester_name}
              />
            </DataBox>
            <DataBox title="Assignee">
              <Chip
                variant="outlined"
                avatar={
                  <Avatar
                    alt={data.assignee_name}
                    src={data.assignee_picture}
                  />
                }
                label={data.assignee_name}
              />
            </DataBox>
            <DataBox title="Created">
              <Typography variant="body1">
                {dateObjFormatToAnnArborDateTime(data.created_on)}
              </Typography>
            </DataBox>
          </Stack>
        </Grid2>
        {data.siblings.length > 0 && (
          <Grid2 xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ fontSize: "larger" }}>
                Sibling Tasks attached to{" "}
                <TicketContextLink
                  context={{
                    label: data.context_label,
                    type: data.context_type,
                    ident: data.context_ident,
                  }}
                  def="(none)"
                />
              </Typography>
              <TicketContextTable tickets={data.siblings} />
            </Paper>
          </Grid2>
        )}

        <Grid2 xs={12}>
          <Tabs value={tabVal} onChange={(_, newVal) => setTabVal(newVal)}>
            <Tab label="Comments" value="comments" />
            <Tab label="Changelog" value="changelog" />
          </Tabs>
        </Grid2>
        <Grid2 xs={12}>
          {tabVal === "comments" && (
            <CommentFeed
              api={api}
              type="ticket"
              id={data.id}
              onNewComment={() => mutate()}
              disablePublic
              ignoreCategories
            />
          )}
          {tabVal === "changelog" && (
            <TicketChangelogTable changelog={data.changelog} />
          )}
        </Grid2>
      </Grid2>

      <StdConfirmSubmitDialog
        title="Finalize Task?"
        open={!!openFinalize}
        handleClose={handleFinalizeClose}
        onSubmit={() => api.updateTicketStatus(data.id, openFinalize)}
        onComplete={() => {
          mutate();
          handleFinalizeClose();
        }}
      >
        Once this is done the ticket will not be able to be edited again!
      </StdConfirmSubmitDialog>
      <TicketDescriptionFormDialog
        open={openDesc}
        title={data.title}
        description={data.description}
        api={api}
        onClose={handleDescClose}
        onSuccess={() => mutate()}
        ticketId={data.id}
      />
      <TicketReassignDialog
        open={!!openReassign}
        onClose={handleReassignClose}
        ticketId={data.id}
        api={api}
        userId={
          openReassign === "owner"
            ? data.requester_id.toString()
            : data.assignee_id.toString()
        }
        onSuccess={() => mutate()}
        type={openReassign}
      />
      <TicketAddCCDialog
        open={openCC}
        ticketId={data.id}
        api={api}
        onClose={handleCCClose}
        onSuccess={() => mutate()}
        curCCList={data.cc}
      />
      <TicketFollowUpFormDialog
        open={openFollowUp}
        ticketId={data.id}
        api={api}
        onClose={handleFollowUpClose}
        onSuccess={() => mutate()}
        followUp={data.follow_up_on}
      />
    </>
  );
};

export default PageTicket;
