import { useEffect, useState } from "react";
import { Map, Record, Set } from "immutable";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Divider,
  Fab,
  Grid,
  Paper,
  Slide,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { distillPartLateralFromDesc, reformatDate } from "../../../utility";
import Icd10PickerForm from "./Icd10PickerForm";
import Icd10PickerList from "./Icd10PickerList";
import Icd10Diagnosis from "./Icd10Diagnosis";
import TreatmentSiteList from "./TreatmentSiteList";
import TreatmentSiteDialog from "./TreatmentSiteDialog";
import NTextField from "../../FormControls2/NTextField";
import NDateField from "../../FormControls2/NDateField";
import NGenderField from "../../FormControls2/NGenderField";
import NSsnField from "../../FormControls2/NSsnField";
import NStateField from "../../FormControls2/NStateField";
import NZipField from "../../FormControls2/NZipField";
import NPhoneField from "../../FormControls2/NPhoneField";
import NLanguageField from "../../FormControls2/NLanguageField";
import NCheckboxField from "../../FormControls2/NCheckboxField";
import SectionHeading from "../../PagePartials/SectionHeading";
import SplitButton from "../../StandardComponents/SplitButton";
import StdConfirmSubmitDialog from "../../PagePartials/StdConfirmSubmitDialog";
import IntakeErrorCard from "./IntakeErrorCard";
import NSerializedItemField from "../../FormControls2/NSerializedItemField";
import NOrgField from "../../FormControls2/NOrgField";
import { InternalLink } from "../../StandardComponents/InternalLink";
import StdFormDialog from "../../PagePartials/StdFormDialog";
import RPTextArea from "../../FormControls/RPTextArea";
import CommentFeed from "../../StandardComponents/CommentFeed";

const FORM_FIELD_SPACING = 2;

const ApiCallResultSnackbar = ({ open, onClose, isSuccess, message }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        variant="filled"
        severity={isSuccess ? "success" : "error"}
        onClose={onClose}
        sx={{ width: "500px" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

const GridDivider = ({ size, text }) => (
  <Grid item xs={size || 16}>
    {text ? (
      <Divider
        sx={{ mb: 3, color: "text.secondary", textTransform: "uppercase" }}
      >
        {text}
      </Divider>
    ) : (
      <Divider sx={{ mb: 3 }} />
    )}
  </Grid>
);

const GridTitle = ({ title }) => {
  if (!title) {
    return <Grid item xs={4} />;
  }

  return (
    <Grid item xs={4}>
      <Typography variant="h6" sx={{ color: grey[700] }}>
        {title}
      </Typography>
    </Grid>
  );
};

const mkFld = Record({
  name: "",
  label: "",
  initial: "",
  value: "",
  error: false,
  errstr: " ",
  validators: [],
});

const mkIcd10Code = Record({
  code: "",
  description: "",
  diag: false,
});

const vldDate = (dateStr) => {
  const dateNorm = dateStr.replaceAll(/\D/g, "");
  if (!dateNorm) return " ";
  if (dateNorm.length !== 8) {
    return "Invalid date";
  }

  const dobj = new Date(reformatDate(dateStr));
  if (dobj.toString() === "Invalid Date") {
    return "Invalid date";
  }

  return " ";
};

const mkNumVldr = (len, msg, optionalMax) => {
  return (val) => {
    const valNorm = val.replaceAll(/\D/g, "");
    if (!valNorm) return " ";

    if (optionalMax) {
      if (valNorm.length > optionalMax || valNorm.length < len) {
        return msg;
      }
      return " ";
    }

    if (valNorm.length !== len) {
      return msg;
    }

    return " ";
  };
};

const vldEmail = (email) => {
  if (!email) return " ";
  const emailPtn = new RegExp("^[^@]+@[^@]+$");
  if (!emailPtn.test(email)) {
    return "Invalid email";
  }
  return " ";
};

const vldRxLen = (rxLen) => {
  if (!rxLen) return " ";
  if (!/^\d{1,3}$/.test(rxLen)) {
    return "Invalid RX Length";
  }
  return " ";
};

const blankFormData = Map({
  date_received: mkFld({
    name: "date_received",
    label: "Date Received",
    validators: [vldDate],
  }),
  referral: mkFld({ name: "referral", label: "Referral" }),
  name_first: mkFld({ name: "name_first", label: "First Name" }),
  name_last: mkFld({ name: "name_last", label: "Last Name" }),
  date_of_birth: mkFld({
    name: "date_of_birth",
    label: "Date of Birth",
    validators: [vldDate],
  }),
  gender: mkFld({ name: "gender", label: "Gender" }),
  ssn: mkFld({
    name: "ssn",
    label: "Social Security Number",
    validators: [mkNumVldr(9, "Invalid SSN")],
  }),
  lang: mkFld({ name: "lang", label: "Language Preference" }),
  addr_street1: mkFld({ name: "addr_street1", label: "Street Address" }),
  addr_street2: mkFld({ name: "addr_street2", label: "Apt / Suite" }),
  addr_city: mkFld({ name: "addr_city", label: "City" }),
  addr_state: mkFld({ name: "addr_state", label: "State" }),
  addr_zip: mkFld({
    name: "addr_zip",
    label: "Zip Code",
    validators: [mkNumVldr(5, "Invalid Zip Code")],
  }),
  phone: mkFld({
    name: "phone",
    label: "Phone Number",
    validators: [mkNumVldr(10, "Invalid Phone Number", 16)],
  }),
  email: mkFld({
    name: "email",
    label: "Email Address",
    validators: [vldEmail],
  }),
  date_of_injury: mkFld({
    name: "date_of_injury",
    label: "Date of Injury",
    validators: [vldDate],
  }),
  employer_name: mkFld({ name: "employer_name", label: "Employer Name" }),
  employer_phone: mkFld({ name: "employer_phone", label: "Employer Phone" }),
  icd10s: mkFld({ name: "icd10s", label: "ICD-10 Codes" }),
  diagnosis: mkFld({ name: "diagnosis", label: "Diagnosis" }),
  rx_length: mkFld({
    name: "rx_length",
    label: "Script Length (Days)",
    validators: [vldRxLen],
  }),
  surgical: mkFld({ name: "surgical", label: "Surgical" }),
  date_of_surgery: mkFld({
    name: "date_of_surgery",
    label: "Date of Surgery",
    validators: [vldDate],
  }),
  item_id: mkFld({ name: "item_id", label: "Unit" }),
  treatment_sites: mkFld({ name: "treatment_sites", label: "Treatment Sites" }),
  claim_number: mkFld({ name: "claim_number", label: "Claim Number" }),
  org_id: mkFld({ name: "org_id", label: "Payor Organization" }),
  ins_addr_street1: mkFld({
    name: "ins_addr_street1",
    label: "Payor Street Address",
  }),
  ins_addr_street2: mkFld({
    name: "ins_addr_street2",
    label: "Payor Suite/PO Box",
  }),
  ins_addr_city: mkFld({ name: "ins_addr_city", label: "Payor City" }),
  ins_addr_state: mkFld({ name: "ins_addr_state", label: "Payor State" }),
  ins_addr_zip: mkFld({ name: "ins_addr_zip", label: "Payor Zip" }),
  adj_name: mkFld({ name: "adj_name", label: "Adjuster Name" }),
  adj_email: mkFld({ name: "adj_email", label: "Adjuster Email" }),
  adj_phone: mkFld({ name: "adj_phone", label: "Adjuster Phone" }),
  adj_fax: mkFld({ name: "adj_fax", label: "Adjuster Fax" }),
  ncm_name: mkFld({ name: "ncm_name", label: "Nurse Case Manager Name" }),
  ncm_email: mkFld({ name: "ncm_email", label: "Nurse Case Manager Email" }),
  ncm_phone: mkFld({ name: "ncm_phone", label: "Nurse Case Manager Phone" }),
  ncm_fax: mkFld({ name: "ncm_fax", label: "Nurse Case Manager Fax" }),
});

const setupInitialFormState = (pageData, req, cautions) => {
  const formData = blankFormData.reduce((reduction, fldRec, key) => {
    let valToSet = pageData[key];
    if (key === "treatment_sites") {
      valToSet = Set(pageData[key]);
    }
    if (key === "icd10s") {
      valToSet = Set(pageData[key].map((k) => mkIcd10Code(k)));
    }
    const updatedRec = fldRec.set("value", valToSet).set("initial", valToSet);
    return reduction.set(key, updatedRec);
  }, Map());

  return Map({
    formData,
    fieldError: false,
    reqViolations: req,
    cautionViolations: cautions,
  });
};

const formDataHasErrors = (formData) =>
  formData.reduce((prev, rec) => {
    if (prev === true) return true;
    return rec.error;
  }, false);

const formDataUpdate = (formState, fieldName, newValue) => {
  const fieldRecord = formState.getIn(["formData", fieldName]);
  if (newValue === formState.getIn(["formData", fieldName, "value"])) {
    return formState;
  }
  const errMsg = fieldRecord.validators.reduce((e, v) => {
    if (e !== " ") return e;
    return v(newValue);
  }, " ");
  const newRecord = fieldRecord.withMutations((r) =>
    r
      .set("value", newValue)
      .set("error", errMsg !== " ")
      .set("errstr", errMsg)
  );
  return formState.withMutations((fs) => {
    const newFs = fs.setIn(["formData", fieldName], newRecord);
    return newFs.set("fieldError", formDataHasErrors(newFs.get("formData")));
  });
};

const IntakeRW = ({ pageData, api, refresh }) => {
  const navigate = useNavigate();
  const [siteDialogOpen, setSiteDialogOpen] = useState(false);
  const [rejConfirmDialogOpen, setRejConfirmDialogOpen] = useState(false);
  const [rejReason, setRejReason] = useState("");

  const [saveSnackbar, setSaveSnackbar] = useState({
    isSuccess: false,
    message: "",
    open: false,
  });
  const [delConfirmDialogOpen, setDelConfirmDialogOpen] = useState(false);
  const [accConfirmDialogOpen, setAccConfirmDialogOpen] = useState(false);
  const [formState, setFormState] = useState(() =>
    setupInitialFormState(pageData.form, pageData.req, pageData.cau)
  );

  useEffect(() => {
    setFormState(
      setupInitialFormState(pageData.form, pageData.req, pageData.cau)
    );
  }, [pageData]);

  const handleSaveSnackbarClose = () =>
    setSaveSnackbar((prev) => ({ ...prev, open: false }));
  const handleSaveSnackbarOpen = (isSuccess, message) =>
    setSaveSnackbar({ isSuccess, message, open: true });

  const handleDelConfirmDialogOpen = () => setDelConfirmDialogOpen(true);
  const handleDelConfirmDialogClose = () => setDelConfirmDialogOpen(false);
  const handleRejConfirmDialogOpen = () => setRejConfirmDialogOpen(true);
  const handleRejConfirmDialogClose = () => setRejConfirmDialogOpen(false);
  const handleAccConfirmDialogOpen = () => setAccConfirmDialogOpen(true);
  const handleAccConfirmDialogClose = () => setAccConfirmDialogOpen(false);

  const fd = formState.get("formData");

  const handleUpdateField = (fieldName, newValue) => {
    setFormState((prev) => formDataUpdate(prev, fieldName, newValue));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = fd.map((fieldRecord) => fieldRecord.value).toJS();
    payload.date_of_surgery = reformatDate(payload.date_of_surgery);
    payload.date_of_injury = reformatDate(payload.date_of_injury);
    payload.date_of_birth = reformatDate(payload.date_of_birth);
    payload.date_received = reformatDate(payload.date_received);
    payload.icd10s = payload.icd10s.map((i) => i.code);
    api
      .updateIntake(pageData.form.id, payload)
      .then((resp) => {
        if (resp.status === "ok") {
          refresh();
          handleSaveSnackbarOpen(true, "Intake data saved!");
          return;
        }
        handleSaveSnackbarOpen(false, resp.message);
      })
      .catch((err) => {
        handleSaveSnackbarOpen(false, err.message);
      });
  };

  const addCodeFn = (code) => {
    const codeRec = mkIcd10Code(code);
    const newVal = formState
      .getIn(["formData", "icd10s", "value"])
      .add(codeRec);
    handleUpdateField("icd10s", newVal);
  };

  const remCodeFn = (code) => {
    const newVal = formState
      .getIn(["formData", "icd10s", "value"])
      .delete(code);
    handleUpdateField("icd10s", newVal);
  };

  const addSiteFn = (site) => {
    let newVal;
    if (Array.isArray(site)) {
      newVal = formState
        .getIn(["formData", "treatment_sites", "value"])
        .concat(site);
    } else {
      newVal = formState
        .getIn(["formData", "treatment_sites", "value"])
        .add(site);
    }
    handleUpdateField("treatment_sites", newVal);
  };

  const remSiteFn = (site) => {
    const newVal = formState
      .getIn(["formData", "treatment_sites", "value"])
      .delete(site);
    handleUpdateField("treatment_sites", newVal);
  };

  const selCodeFn = (code) => {
    // this could be improved such that you can call handleUpdateField() once to
    // change both treatment_sites AND icd10s in one go rather than causing two
    // re-renders
    const newVal = formState.getIn(["formData", "icd10s", "value"]).map((c) => {
      if (c === code) {
        if (c.diag === false) {
          const guess = distillPartLateralFromDesc(c.code, c.description);
          if (guess) {
            addSiteFn(guess);
          }
          handleUpdateField("diagnosis", c.description);
        } else {
          handleUpdateField("diagnosis", "");
        }
        return c.set("diag", !c.diag);
      }
      return c.set("diag", false);
    });
    handleUpdateField("icd10s", newVal);
  };

  const icd10sSet = formState.getIn(["formData", "icd10s", "value"]);

  return (
    <>
      <SectionHeading
        headingLabel={`Intake #${pageData.form.id.toString().padStart(4, "0")}`}
        buttonEl={
          <SplitButton
            optsAndClicks={{
              Reject: handleRejConfirmDialogOpen,
              Delete: handleDelConfirmDialogOpen,
            }}
          />
        }
      />
      <IntakeErrorCard
        reqViolations={formState.get("reqViolations")}
        cautions={formState.get("cautionViolations")}
        disableButton={formState.get("fieldError")}
        onClickStatus={handleAccConfirmDialogOpen}
        onClick={() => handleFormSubmit({ preventDefault: () => {} })}
        showAction={
          pageData.form.status !== "incomplete" ||
          formState.get("reqViolations").length === 0
        }
      />
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <Grid container columns={16} columnSpacing={2} rowSpacing={1}>
          <GridTitle title="Attribution" />
          <Grid item xs={12}>
            <Box sx={{ pt: 1, pb: 1 }}>
              <Typography>
                <InternalLink to={`/prescribers/${pageData.form.rxer_npi}`}>
                  {pageData.form.rxer_name_first} {pageData.form.rxer_name_last}{" "}
                  {pageData.form.rxer_credential}
                </InternalLink>{" "}
                /{" "}
                <InternalLink to={`/reps/${pageData.form.rep_id}`}>
                  {pageData.form.rep_name}
                </InternalLink>{" "}
                /{" "}
                <InternalLink to={`/agencies/${pageData.form.agency_id}`}>
                  {pageData.form.agency_name}
                </InternalLink>
              </Typography>
            </Box>
            <Box sx={{ pb: 3 }}>
              Service Channel:{" "}
              <strong>{pageData.form.computed_service_channel}</strong>
            </Box>
          </Grid>
          <GridTitle />
          <Grid item xs={3}>
            <NDateField
              fieldRecord={fd.get("date_received")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={3}>
            <NCheckboxField
              fieldRecord={fd.get("referral")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={9} />
          <GridDivider />
          <GridTitle title="Personal" />
          <Grid item xs={4}>
            <NTextField
              fieldRecord={fd.get("name_first")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={5}>
            <NTextField
              fieldRecord={fd.get("name_last")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={3}>
            <NDateField
              fieldRecord={fd.get("date_of_birth")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />
          <Grid item xs={3}>
            <NGenderField
              fieldRecord={fd.get("gender")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={5}>
            <NSsnField
              fieldRecord={fd.get("ssn")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={4}>
            <NLanguageField
              fieldRecord={fd.get("lang")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridDivider />
          <GridTitle title="Address / Contact" />
          <Grid item xs={7}>
            <NTextField
              fieldRecord={fd.get("addr_street1")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={5}>
            <NTextField
              fieldRecord={fd.get("addr_street2")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />
          <Grid item xs={5}>
            <NTextField
              fieldRecord={fd.get("addr_city")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={4}>
            <NStateField
              fieldRecord={fd.get("addr_state")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={3}>
            <NZipField
              fieldRecord={fd.get("addr_zip")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />
          <GridDivider size={12} text="Contact" />
          <GridTitle />
          <Grid item xs={8}>
            <NTextField
              fieldRecord={fd.get("email")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={4}>
            <NPhoneField
              fieldRecord={fd.get("phone")}
              updater={handleUpdateField}
            />
          </Grid>

          <GridDivider />
          <GridTitle title="Injury" />
          <Grid item xs={3}>
            <NDateField
              fieldRecord={fd.get("date_of_injury")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={5}>
            <NTextField
              fieldRecord={fd.get("employer_name")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={4}>
            <NPhoneField
              fieldRecord={fd.get("employer_phone")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridDivider />
          <GridTitle title="Diagnosis" />
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              elevation={0}
              sx={{ borderColor: grey[400], p: 2, mb: 3 }}
            >
              <Typography fontSize="larger" color={grey[700]} sx={{ mb: 1 }}>
                {fd.getIn(["icd10s", "label"])}
              </Typography>
              <Icd10PickerForm
                columnSpacing={FORM_FIELD_SPACING}
                rowSpacing={1}
                btnGridSize={3}
                iptGridSize={4}
                addCodeFn={addCodeFn}
                api={api}
              />

              <Icd10PickerList
                codes={icd10sSet}
                remCodeFn={remCodeFn}
                selCodeFn={selCodeFn}
              />
            </Paper>
            <Paper
              variant="outlined"
              elevation={0}
              sx={{ borderColor: grey[400], p: 2, mb: 3 }}
            >
              <Typography fontSize="larger" color={grey[700]} sx={{ mb: 1 }}>
                Diagnosis
              </Typography>
              <Icd10Diagnosis codes={icd10sSet} />
            </Paper>
          </Grid>
          <GridDivider />
          <GridTitle title="Treatment" />
          <Grid item xs={4}>
            <NSerializedItemField
              fieldRecord={fd.get("item_id")}
              updater={handleUpdateField}
            />
          </Grid>

          <Grid item xs={3}>
            <NTextField
              fieldRecord={fd.get("rx_length")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={2}>
            <NCheckboxField
              fieldRecord={fd.get("surgical")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={3}>
            <NDateField
              fieldRecord={fd.get("date_of_surgery")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />
          <Grid item xs={12}>
            <TreatmentSiteList
              remSiteFn={remSiteFn}
              siteDialogOpenFn={() => setSiteDialogOpen(true)}
              treatmentSites={fd.getIn(["treatment_sites", "value"])}
            />
          </Grid>
          <GridDivider />
          <GridTitle title="Insurance" />
          <Grid item xs={6}>
            <NOrgField
              fieldRecord={fd.get("org_id")}
              updater={handleUpdateField}
              api={api}
            />
          </Grid>
          <Grid item xs={6}>
            <NTextField
              fieldRecord={fd.get("claim_number")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />
          <Grid item xs={7}>
            <NTextField
              fieldRecord={fd.get("ins_addr_street1")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={5}>
            <NTextField
              fieldRecord={fd.get("ins_addr_street2")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />
          <Grid item xs={5}>
            <NTextField
              fieldRecord={fd.get("ins_addr_city")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={4}>
            <NStateField
              fieldRecord={fd.get("ins_addr_state")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={3}>
            <NZipField
              fieldRecord={fd.get("ins_addr_zip")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />
          <GridDivider size={12} text="Adjuster" />
          <GridTitle />
          <Grid item xs={5}>
            <NTextField
              fieldRecord={fd.get("adj_name")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={7}>
            <NTextField
              fieldRecord={fd.get("adj_email")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />
          <Grid item xs={6}>
            <NPhoneField
              fieldRecord={fd.get("adj_phone")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={6}>
            <NPhoneField
              fieldRecord={fd.get("adj_fax")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />

          <GridDivider size={12} text="Nurse Case Manager" />
          <GridTitle />
          <Grid item xs={5}>
            <NTextField
              fieldRecord={fd.get("ncm_name")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={7}>
            <NTextField
              fieldRecord={fd.get("ncm_email")}
              updater={handleUpdateField}
            />
          </Grid>
          <GridTitle />
          <Grid item xs={6}>
            <NPhoneField
              fieldRecord={fd.get("ncm_phone")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={6}>
            <NPhoneField
              fieldRecord={fd.get("ncm_fax")}
              updater={handleUpdateField}
            />
          </Grid>
          <Grid item xs={16}>
            <CommentFeed api={api} type="intake" id={pageData.form.id} />
          </Grid>
        </Grid>
      </form>
      <ApiCallResultSnackbar
        onClose={handleSaveSnackbarClose}
        open={saveSnackbar.open}
        isSuccess={saveSnackbar.isSuccess}
        message={saveSnackbar.message}
      />
      <TreatmentSiteDialog
        open={siteDialogOpen}
        addSiteFn={addSiteFn}
        ignore={fd.getIn(["treatment_sites", "value"])}
        onClose={() => setSiteDialogOpen(false)}
      />
      <StdFormDialog
        open={rejConfirmDialogOpen}
        onClose={handleRejConfirmDialogClose}
        fields={[
          {
            type: "plain",
            component: Box,
            extraProps: {
              children: (
                <Alert severity="info">
                  Rejecting a script means the script really should have never
                  been submitted to WRS in the first place. An example would be
                  a script involving a Payor that doesn't do any workman's comp
                  claims.
                </Alert>
              ),
            },
          },
          {
            name: "reason",
            component: RPTextArea,
            label:
              "Reason - This will be sent along in the notification to the Sales Force!",
          },
        ]}
        title="Reject?"
        submitCall={(data) => api.rejectIntake(pageData.form.id, data)}
        onComplete={() => navigate("/intake")}
      >
        <TextField
          fullWidth
          label="Reason"
          value={rejReason}
          onChange={(e) => setRejReason(e.target.value)}
          variant="outlined"
          margin="normal"
        />
      </StdFormDialog>
      <StdConfirmSubmitDialog
        open={accConfirmDialogOpen}
        handleClose={handleAccConfirmDialogClose}
        title={!pageData.req.length ? "Accept Script?" : "Mark Incomplete?"}
        onSubmit={() => api.acceptIntake(pageData.form.id)}
        onComplete={() => navigate("/intake")}
      >
        This action cannot be undone!
      </StdConfirmSubmitDialog>
      <StdConfirmSubmitDialog
        open={delConfirmDialogOpen}
        handleClose={handleDelConfirmDialogClose}
        title="Delete intake file?"
        onSubmit={() => api.deleteIntake(pageData.form.id)}
        onComplete={() => navigate("/intake")}
      >
        Only do this if the record should never have been added to the system in
        the first place. Examples include accidentally duplicate entries for the
        same patient or if the wrong prescriber was picked by accident.
      </StdConfirmSubmitDialog>
      <Fab
        variant="extended"
        size="large"
        color="primary"
        disabled={formState.get("fieldError")}
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => handleFormSubmit({ preventDefault: () => {} })}
      >
        Save & Check
      </Fab>
    </>
  );
};

export default IntakeRW;
