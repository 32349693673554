import { InternalLink } from "../Components/StandardComponents/InternalLink";
import {
  WPIncompleteLink,
  WSPatientAndRxLink,
  WSUserAvatar,
} from "./ws-shared";
import TagIconList from "../Components/StandardComponents/TagIconList";
import { differenceInDays } from 'date-fns'
import {daysRelative} from "../utility";

export const practiceSection = {
  title: "Practice Team",
  key: "sec-practice",
  buckets: ["prac-ext-offer", "prac-incomplete"],
};

export const practiceExtensionOffer = [
  "prac-ext-offer",
  {
    title: "Awaiting Extension Authorization",
    description:
      "Extendable scripts that need authorization from the practice to extend.",
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientAndRxLink
            rx_id={r.id}
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
          />
        ),
      },
      {
        label: "Practice",
        cell: (r) => (
          <InternalLink to={`/practices/${r.practice_id}`}>
            {r.practice_name}
          </InternalLink>
        ),
      },
      {
        label: "Tags",
        cell: (r) => (
          <TagIconList
            patient={{
              referral: r.referral,
              practice_engagement: r.practice_engagement,
              porg_id: r.porg_id,
              ext_process: r.ext_process,
            }}
          />
        ),
      },
      {
        label: "Contact",
        cell: (r) => {
          const name = [r.contact_name_first, r.contact_name_last]
            .join(" ")
            .trim();
          return name.length > 14 ? `${name.substring(0, 12)}…` : name;
        },
      },
      {
        label: "Remaining",
        cell: (r) => daysRelative(r.remaining_days),
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const practiceIncomplete = [
  "prac-incomplete",
  {
    title: "Incompletes",
    description:
      "Incomplete intake records, or orders marked billing incomplete, that require followup.",
    highlight: (r) => {
      const dateDiff = differenceInDays(new Date(), new Date(r.date_received));
      if (dateDiff >= 30) return 'bgError';
      if (dateDiff >= 14) return 'bgCaution';
      return null;
    },

    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <nobr>
            <WPIncompleteLink
              link_target={r.link_target}
              link_ident={r.link_ident}
              name_link_target={r.name_link_target}
              name_link_ident={r.name_link_ident}
              name_first={r.name_first}
              name_last={r.name_last}
            />
          </nobr>
        ),
      },
      {
        label: "Missing Info",
        cell: (r) => r.status_memo,
      },
      {
        label: "Practice",
        cell: (r) => (
          <InternalLink to={`/practices/${r.practice_id}`}>
            {r.practice_name}
          </InternalLink>
        ),
      },
      {
        label: "Contact",
        cell: (r) => {
          const name = [r.contact_name_first, r.contact_name_last]
            .join(" ")
            .trim();
          return name.length > 14 ? `${name.substring(0, 12)}…` : name;
        },
      },
      {
        label: "Age",
        cell: (r) => {
          const days = differenceInDays(new Date(), new Date(r.date_received));
          let str = '';
          if (days < 1) str = '< 1 day';
          if (days === 1) str = '1 day';
          if (days > 1) str = `${days} days`;
          return str;
        }
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];
